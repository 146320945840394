import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.stepupjapanese.com/blog/2018/05/gachapon-capsules-of-joy-300-pop-guest.html"
      }}>{`Gachapon: Capsules of Joy, ¥300 a pop`}</a>{` - a blog post I wrote for friend and Japanese teacher, Fran, back in 2018 about Gachapon and full of photos of gachapon machines`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      